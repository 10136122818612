import { getOptionsFromArray, getOptionsNameMap } from '../../helpers/utils';
import paths from '../../paths';
import { canShowRuv } from './utils';

export const FINANCING_TYPE_OPTIONS = [
  {
    label: 'Equity Round',
    value: 'equity_financing',
  },
  {
    label: 'SAFE Round',
    value: 'safe_financing',
  },
  {
    label: 'Convertible Note',
    value: 'debt_financing',
  },
];

export const FINANCING_TYPE_MAP = getOptionsNameMap(FINANCING_TYPE_OPTIONS);

export const INVESTMENT_TEMPLATE_OPTIONS = [
  {
    label: 'Valuation Cap',
    value: 'val_cap',
  },
  {
    label: 'Discount',
    value: 'discount',
  },
  {
    label: 'Valuation Cap + Discount',
    value: 'val_cap_discount',
  },
  {
    label: 'MFN',
    value: 'mfn',
  },
];

export const INVESTMENT_TEMPLATE_MAP = getOptionsNameMap(
  INVESTMENT_TEMPLATE_OPTIONS,
);

export const MAIN_STAGES = [
  {
    label: 'Angel round',
    value: 'Angel Round',
  },
  {
    label: 'Pre-Seed',
    value: 'Preseed',
  },
  {
    label: 'Seed',
    value: 'Seed',
  },
  {
    label: 'Series A',
    value: 'Series A',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];

export const ENTITY_TYPE_OPTIONS = [
  {
    label: 'C Corporation',
    value: 'Corporation',
  },
  {
    label: 'LLC',
    value: 'LLC',
  },
  {
    label: 'PBC',
    value: 'PBC',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];

export const ROUND_TYPES = {
  DIRECT: 'financing_round',
  RUV: 'ruv',
  DIRECT_AND_RUV: 'financing_round_with_ruv',
};

export const COMPANY_INDUSTRY_OPTIONS = [
  { label: 'AI / ML', value: 'AI / ML' },
  { label: 'Adtech', value: 'Adtech' },
  { label: 'Aerospace', value: 'Aerospace' },
  { label: 'Analytics', value: 'Analytics' },
  { label: 'Biotech', value: 'Biotech' },
  { label: 'Blockchain / Crypto', value: 'Blockchain / Crypto' },
  { label: 'Cannabis', value: 'Cannabis' },
  { label: 'Cleantech', value: 'Cleantech' },
  { label: 'Collaboration Tools', value: 'Collaboration Tools' },
  { label: 'Consumer', value: 'Consumer' },
  { label: 'Consumer Product', value: 'Consumer Product' },
  { label: 'Dating', value: 'Dating' },
  { label: 'Developer Tools', value: 'Developer Tools' },
  { label: 'Drones', value: 'Drones' },
  { label: 'E-Commerce', value: 'E-Commerce' },
  { label: 'Education', value: 'Education' },
  { label: 'Enterprise', value: 'Enterprise' },
  { label: 'Finance', value: 'Finance' },
  { label: 'Fitness', value: 'Fitness' },
  { label: 'Food / Beverages', value: 'Food / Beverages' },
  { label: 'Gaming', value: 'Gaming' },
  { label: 'HR & Recruiting', value: 'HR & Recruiting' },
  { label: 'Hardware', value: 'Hardware' },
  { label: 'Health', value: 'Health' },
  { label: 'Healthcare', value: 'Healthcare' },
  { label: 'Home Services', value: 'Home Services' },
  { label: 'Insurance', value: 'Insurance' },
  { label: 'Internet of Things', value: 'Internet of Things' },
  { label: 'Investment Platforms', value: 'Investment Platforms' },
  { label: 'Logistics', value: 'Logistics' },
  { label: 'Media / Entertainment', value: 'Media / Entertainment' },
  { label: 'Medical Devices', value: 'Medical Devices' },
  { label: 'Non Tech', value: 'Non Tech' },
  { label: 'On Demand', value: 'On Demand' },
  { label: 'Payments', value: 'Payments' },
  { label: 'Productivity', value: 'Productivity' },
  { label: 'Real Estate', value: 'Real Estate' },
  { label: 'Retail', value: 'Retail' },
  { label: 'Robotics', value: 'Robotics' },
  { label: 'SaaS', value: 'SaaS' },
  { label: 'Sales Tools', value: 'Sales Tools' },
  { label: 'Security', value: 'Security' },
  { label: 'Social', value: 'Social' },
  { label: 'Social Impact', value: 'Social Impact' },
  { label: 'Transportation', value: 'Transportation' },
  { label: 'Travel', value: 'Travel' },
  { label: 'VR / AR', value: 'VR / AR' },
  { label: 'Other', value: 'Other' },
];

export const ROUND_TYPE_OPTIONS = [
  {
    label: 'Direct investments',
    description:
      'Invite investors to sign SAFE, Convertible Note, or Equity documents directly.',
    value: ROUND_TYPES.DIRECT,
    icon: 'privateRounds',
    isDisabled: false,
  },
  {
    label: 'Roll Up Vehicle™',
    description:
      'Raise big and small checks from up to 250 investors in a single line on your cap table. Included in select plans, available as an add-on.',
    value: ROUND_TYPES.RUV,
    icon: 'ruvs',
    isDisabled: false,
  },
  {
    label: 'Direct Investment + Roll Up Vehicle',
    description:
      'Raise money directly and via an RUV with the same terms. Choose how to invite each investor.',
    value: ROUND_TYPES.DIRECT_AND_RUV,
    icon: 'publicRounds',
    isDisabled: false,
  },
];

export const STAGE_LIST = [
  'Angel Round',
  'Preseed',
  'Seed',
  'Seed+',
  'Series A',
  'Series A+',
  'Series B',
  'Series B+',
  'Series C',
  'Series C+',
  'Series D',
  'Series E',
  'Series F',
  'Other',
];

export const STAGE_OPTIONS = getOptionsFromArray(STAGE_LIST);

export const SAFE_TYPE_OPTIONS = [
  {
    value: 'pre_money',
    label: 'Pre-money SAFE',
  },
  {
    value: 'post_money',
    label: 'Post-money SAFE',
  },
];

export const SAFE_TYPE_MAP = getOptionsNameMap(SAFE_TYPE_OPTIONS);

export const INSTRUMENT_TYPE = {
  SAFE: 'safe',
  EQUITY: 'equity',
  WARRANT: 'warrant',
  NOTE: 'note',
  OTHER: 'other',
  EMERGENCY_ROUND: 'emergency_round',
};

export const INSTRUMENT_TYPE_OPTIONS = [
  {
    label: 'SAFE',
    value: INSTRUMENT_TYPE.SAFE,
  },
  {
    label: 'Equity',
    value: INSTRUMENT_TYPE.EQUITY,
  },
  {
    label: 'Warrant',
    value: INSTRUMENT_TYPE.WARRANT,
  },
  {
    label: 'Note',
    value: INSTRUMENT_TYPE.NOTE,
  },
  {
    label: 'Other',
    value: INSTRUMENT_TYPE.OTHER,
  },
  {
    label: 'Emergency Round',
    value: INSTRUMENT_TYPE.EMERGENCY_ROUND,
  },
];

export const INSTRUMENT_TYPE_MAP = getOptionsNameMap(INSTRUMENT_TYPE_OPTIONS);

export const VALUATION_TYPE_OPTIONS = [
  { value: 'pre_money', label: 'Pre-money' },
  { value: 'post_money', label: 'Post-money' },
];

export const VALUATION_TYPE_MAP = getOptionsNameMap(VALUATION_TYPE_OPTIONS);

export const VENTURE_VALUATION_TYPE_MAP: { [key: string]: string } = {
  pre: 'Pre-money',
  post: 'Post-money',
};

export const INSTRUMENT_OPTIONS = [
  { label: 'SAFE', value: INSTRUMENT_TYPE.SAFE },
];

export const VALUATION_CAP_OPTIONS = [
  { label: 'Capped', value: 'capped' },
  { label: 'Uncapped', value: 'uncapped' },
];

export const SHARE_CLASS_OPTIONS = [
  { label: 'Common', value: 'common' },
  { label: 'Preferred', value: 'preferred' },
];

export const DEFAULT_TAB = 'investors';

export const getTabItems = (
  instrumentType: string,
  alRuvId: string,
  allowDirectInvestment: boolean,
) => {
  const items = [
    {
      id: 'investors',
      label: 'Investors',
    },
  ];

  if (canShowRuv(instrumentType, alRuvId) && allowDirectInvestment) {
    items.push({ id: 'details', label: 'Direct Details' });
    items.push({
      id: ROUND_TYPES.RUV,
      label: 'RUV Details',
    });
  } else if (allowDirectInvestment) {
    items.push({
      id: 'details',
      label: 'Details',
    });
  } else if (canShowRuv(instrumentType, alRuvId)) {
    items.push({
      id: ROUND_TYPES.RUV,
      label: 'RUV Details',
    });
  }

  if (instrumentType === INSTRUMENT_TYPE.EQUITY && allowDirectInvestment) {
    items.push(
      {
        id: 'documents',
        label: 'Documents',
      },
      {
        id: 'conversion',
        label: 'Existing SAFE/Note Holders',
      },
    );
  }

  items.push({
    id: 'activity',
    label: 'Activity',
  });

  return items;
};

export const COMMIT_STATUS = {
  INVITED: 'no_action',
  CREATED: 'created',
  VIEWED: 'viewed',
  CANCELED: 'canceled',
  COMMITTED: 'committed',
  SIGNED: 'signed',
  EXECUTED: 'executed',
  FUND_RECIEVED: 'fund_recieved',
};

export const INVESTOR_SOURCE = {
  DIRECT: 'direct',
  RUV: 'ruv',
};

export const SOURCE_FILTER_ITEMS = [
  {
    key: 'all',
    value: 'all',
    label: 'All',
  },
  {
    key: INVESTOR_SOURCE.DIRECT,
    value: INVESTOR_SOURCE.DIRECT,
    label: 'Direct',
  },
  {
    key: INVESTOR_SOURCE.RUV,
    value: INVESTOR_SOURCE.RUV,
    label: 'RUV',
  },
];

export const COMMITMENT_STATUS_FILTER_ITEMS = [
  {
    key: COMMIT_STATUS.INVITED,
    value: COMMIT_STATUS.INVITED,
    label: 'Invited',
  },
  {
    key: COMMIT_STATUS.VIEWED,
    value: COMMIT_STATUS.VIEWED,
    label: 'Viewed',
  },
  {
    key: COMMIT_STATUS.CREATED,
    value: COMMIT_STATUS.CREATED,
    label: 'Created',
  },
  {
    key: COMMIT_STATUS.CANCELED,
    value: COMMIT_STATUS.CANCELED,
    label: 'Canceled',
  },
  {
    key: COMMIT_STATUS.COMMITTED,
    value: COMMIT_STATUS.COMMITTED,
    label: 'Committed',
  },
  {
    key: COMMIT_STATUS.SIGNED,
    value: COMMIT_STATUS.SIGNED,
    label: 'Signed',
  },
  {
    key: COMMIT_STATUS.EXECUTED,
    value: COMMIT_STATUS.EXECUTED,
    label: 'Executed',
  },
  {
    key: COMMIT_STATUS.FUND_RECIEVED,
    value: COMMIT_STATUS.FUND_RECIEVED,
    label: 'Funds Received',
  },
];

export const CONVERSION_FILTER_ITEMS = [
  {
    key: 'all',
    value: 'all',
    label: 'All',
  },
  {
    key: COMMIT_STATUS.CREATED,
    value: COMMIT_STATUS.CREATED,
    label: 'Created',
  },
  {
    key: COMMIT_STATUS.EXECUTED,
    value: COMMIT_STATUS.EXECUTED,
    label: 'Signed / Executed',
  },
];

export const ROUND_STATUS = {
  CREATED: 'created',
  CLOSED: 'closed',
};

export const ROUND_FILTER_ITEMS = [
  {
    value: 'all',
    key: 'all',
    label: 'All',
  },
  {
    key: ROUND_STATUS.CREATED,
    value: ROUND_STATUS.CREATED,
    label: 'Raising',
  },
  {
    key: ROUND_STATUS.CLOSED,
    value: ROUND_STATUS.CLOSED,
    label: 'Closed',
  },
];

export const ACTION_FILTER_ITEMS = [
  {
    key: 'Raise View',
    label: 'Raise View',
    value: 'Raise View',
  },
  {
    key: 'Interest Submit',
    label: 'Interest Submission',
    value: 'Interest Submit',
  },
  {
    key: 'E-Sign View',
    label: 'E-Sign View',
    value: 'E-Sign View',
  },
  {
    key: 'E-Sign Submit',
    label: 'E-Sign Submission',
    value: 'E-Sign Submit',
  },
  {
    key: 'Wire Info View',
    label: 'Wire Info View',
    value: 'Wire Info View',
  },
];

export const NULL_SAFE_TEMPLATE_DOCUMENT_ID = 'zzz';

export const roundBreadcrumbs = [
  {
    title: 'Rounds',
    link: paths.raise.rounds,
  },
];

export const RUV_STATUSES = {
  RAISING: 'Raising',
  IN_REVIEW: 'In review',
  CLOSING: 'Closing',
  WIRED: 'Wired',
  CANCELLED: 'Cancelled',
};

export const OVERVIEW_GRID_ITEMS = [
  {
    title: 'SAFEs',
    subtitle: 'Raise money quickly with simple terms.',
    icon: 'signed',
  },
  {
    title: 'Equity Rounds',
    subtitle: 'Invite investors and collect signed docs.',
    icon: 'pricedRound',
  },
  {
    title: 'Roll Up Vehicles™',
    subtitle: 'Raise small checks from your network.',
    icon: 'ruvs',
  },
];
